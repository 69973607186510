import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'


import Layout from '../components/Layout'

// eslint-disable-next-line
import ShareImages from '../fragments/ShareImagesFragment'

import {
  ScrollToTopOfPage,
  ContentfulCategory,
} from 'blog-components'

class CategoryTemplate extends ScrollToTopOfPage {
  render() {
    const site = get(this, 'props.data.site')

    const title = get(this, 'props.data.category.title')
    const description = get(this,'props.data.category.description.childMarkdownRemark.rawMarkdownBody')
    const slug = get(this, 'props.data.category.slug')
    const createdAt = get(this, 'props.data.category.createdAt')
    const updatedAt = get(this, 'props.data.category.updatedAt')
    const shareImage = get(this, 'props.data.category.shareImage.fixed_1200x630.src')

    const subCategories = get(this, 'props.data.category.subcategories')
    const blogPosts = get(this, 'props.data.category.blog_post')
    const productRoundups = get(this, 'props.data.category.productroundup')

    const slot1 = get(this, 'props.data.category.slot1.childMarkdownRemark.htmlAst')
    const slot2 = get(this, 'props.data.category.slot2.childMarkdownRemark.htmlAst')

    return (
      <Layout>
        <div className="container fadein-3 blog-post" data-emergence="visible">
          <div className="row">
            <div className="articles col-12">
              <ContentfulCategory
                slug={slug}
                title={title}
                createdAt={createdAt}
                updatedAt={updatedAt}
                shareImage={shareImage}
                description={description}
                slot1={slot1}
                slot2={slot2}
                site={site}
                subCategories={subCategories}
                blogPosts={blogPosts}
                productRoundups={productRoundups}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryByPath($path: String!) {
    site {
      ...SiteInformation
    }
    category: contentfulCategory(slug: {eq: $path}) {
      title
      slug
      createdAt(formatString: "DD/MM/YYYY")
      updatedAt(formatString: "DD/MM/YYYY")
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      shareImage {
        ...ShareImages
      }
      slot1 {
        childMarkdownRemark {
          htmlAst
        }
      }
      slot2 {
        childMarkdownRemark {
          htmlAst
        }
      }
      blog_post{
        ...SimpleBlogPostSummary
      }
      productroundup {
        ...SimpleProductRoundUpSummary
      }
      subcategories {
        title
        slug
        blog_post{
          ...SimpleBlogPostSummary
        }
        productroundup {
          ...SimpleProductRoundUpSummary
        }
      }
    }

  }
`
